import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import bg from "../../assets/images/sustainability/hero.png";
import middle_bg from "../../assets/images/sustainability/middle.png";
import top_bg from "../../assets/images/sustainability/top.png";
import Layout from "../../common/layout/Layout";

export default function SustainabilityDetails() {
  const { id } = useParams();
  const { sustainabilityData } = useSelector((state) => state.homeData);

  const data = sustainabilityData?.materials?.materials?.find(
    (el) => el?._id === id
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Container fluid className="sustainability_details">
        <div
          className="hero section-gap"
          style={{ backgroundImage: `url(${bg})` }}
        >
          <h2>{data?.title}</h2>
        </div>

        <div>
          <Container className="details_items">
            <h2>Organic cotton fabric</h2>
            <p>
              Organic cotton fabric is the raw material of organic cotton. It is
              a type of cotton that has been grown without the use of any
              pesticides, herbicides, or chemical fertilizers. The organic
              cotton fabric is made from organic raw materials. This type of
              cotton is known for its soft and silky texture. The main benefit
              of using organic cotton fabric is that it helps in reducing the
              harmful effects of pesticides on our body. It also provides relief
              by relieving the skin of pressure and offers natural protection to
              our body against insects. Using organic cotton furnishes you with
              a healthy, comfortable and well-balanced life. Organic fabric is
              made without using any harmful chemical or ionic pesticides which
              make it safe for human consumption.
            </p>
          </Container>

          <div
            className="middle_image"
            style={{ backgroundImage: `url(${top_bg})` }}
          />

          <Container>
            <div className="details_items">
              <h2>Why is organic cotton Fabric important?</h2>
              <p>
                The main reason for choosing Organic cotton fabric is that it is
                a more sustainable way of producing clothing. The natural
                properties of cotton are what make it an organic product. Cotton
                can be grown without the use of pesticides, and it requires less
                water than conventional cotton. We ask the fabric manufacturers
                to use organic cotton for our tees. We truly believe that it is
                an important choice because we want your hands and clothes to be
                as clean when you pull them out of the bag or washing machine,
                as they are when you first put them in! Cotton is a 95%
                renewable resource, so it is particularly important to us that
                we use natural cotton because it will be around forever.
              </p>
            </div>

            <div className="details_items">
              <h2>What is the difference between cotton and organic cotton?</h2>
              <p>
                There are two types of cotton fabrics: regular and organic.
                Regular cotton is grown using pesticides and other chemicals,
                while organic cotton is grown without the use of pesticides or
                chemicals. Regular cotton is a more expensive fabric, while
                organic cotton is a much cheaper one. But the quality of both
                fabrics is similar. The only difference between the two is that
                regular cotton has been treated with pesticides and other
                chemicals, while organic cotton does not have any such
                treatment.
              </p>
            </div>

            <div
              className="middle_image"
              style={{ backgroundImage: `url(${middle_bg})` }}
            />

            <div className="details_items">
              <h2>Production process of cotton fabric</h2>
              <p>
                Organic cotton is one of the most used textile materials, which
                is processed into fabrics and textiles. The raw material for
                organic cotton is cotton, and it is produced by planting seeds
                and letting them grow into plants. The processing of organic
                cotton fabric includes removing the seeds from the cotton,
                bleaching it, dying it and finally cutting it into different
                shapes.
              </p>
            </div>

            <div className="details_items">
              <h2>Best Organic cotton fabric</h2>
              <p>
                Organic cotton fabric CENTRO price in Bangladesh is determined
                by the price of cotton and cost of production. Cotton price is
                the main factor which decides the price of organic cotton fabric
                CENTRO. In Bangladesh, the demand for organic cotton fabric
                CENTRO is increasing day by day. The major reason behind this
                demand is its benefits, health and environment friendly
                properties. Due to this demand, there are many companies that
                are manufacturing organic cotton fabric CENTRO in Bangladesh.
                So, if you want to get a good quality product at an affordable
                price, you can visit our fabricCENTRO.com website to get the
                best quality product at a reasonable price.
              </p>
            </div>

            <div className="details_items">
              <h2>Why choose us CENTRO?</h2>
              <p>
                Our company provides many kinds of natural cotton fabrics at the
                most competitive price. The cotton fabric is made from pure
                cotton and the price is very competitive. We have many types of
                organic cotton fabric and all of them are available in different
                sizes, widths and colors. The fabric is made from pure cotton
                and is free from any chemicals, so it is safe for you to use.
                The cotton fabrics are high in quality and there is no need for
                you to worry about the materials used. The beauty of the fabric
                enhances as it gets tougher with time.
              </p>
            </div>

            <div className="details_items">
              <h2>Is organic cotton expensive?</h2>
              <p>
                There is no one-size-fits-all answer to this question, as the
                price of organic cotton will vary depending on the quality and
                quantity of the fabric. However, on average, organic cotton is
                typically more expensive than conventional cotton. One reason
                for this is that organic cotton is grown without the use of
                pesticides, which can be costly. Additionally, organic farming
                methods require more attention to detail, which leads to higher
                labor costs.
              </p>

              <p>
                Our industry offers discounts for customers who buy organic
                cotton, so it is worth checking out the prices before making a
                purchase. Additionally, many people prefer organic cotton
                because it is thought to be healthier for the wearer and the
                environment. So, if you are looking for a sustainable option,
                organic cotton may be a good choice for you!
              </p>
            </div>

            <div className="details_items">
              <h2>Does organic cotton shrink in the wash?</h2>
              <p>
                There is some debate on whether or not organic cotton shrinks in
                the wash, but the general consensus is that it does. This is
                because conventional cotton is treated with pesticides, which
                can lead to the cotton becoming infested with bugs and moths.
                These insects then defecate on the cotton, which causes it to
                shrink. While there are ways to avoid this (by using organic
                cotton), it is still a potential problem with organic cotton.
              </p>
            </div>
          </Container>
        </div>
      </Container>
    </Layout>
  );
}
