import React from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

const CustomInput = ({
  field: { onChange, ...rest },
  form: { touched, errors },
  label,
  id,
  onChangeCallback,
  ...props
}) => {
  return (
    <React.Fragment>
      <FormGroup className={"border-0"}>
        {label ? <Label htmlFor={id}>{label}</Label> : null}
        <Input
          type="text"
          id={id}
          invalid={touched[rest.name] && errors[rest.name] ? true : false}
          onChange={(e) => {
            const data = e;
            onChangeCallback && onChangeCallback(data);
            onChange && onChange(e);
          }}
          {...rest}
          {...props}
        />
        {touched[rest.name] && errors[rest.name] ? (
          <FormFeedback type="invalid">{errors[rest.name]}</FormFeedback>
        ) : null}
      </FormGroup>
    </React.Fragment>
  );
};

export default CustomInput;
