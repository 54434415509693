import { call, put, takeEvery } from "redux-saga/effects";
import { toaster } from "../../common/Toast";
import { get, post } from "../../helpers/api_helper";
import {
  applyExistingJob,
  applyOpenCv,
  getAboutData,
  getAboutDataFail,
  getAboutDataSuccess,
  getAsEmployeeData,
  getAsEmployeeDataFail,
  getAsEmployeeDataSuccess,
  getAsPartnerData,
  getAsPartnerDataFail,
  getAsPartnerDataSuccess,
  getBlogData,
  getBlogDataFail,
  getBlogDataSuccess,
  getCultureData,
  getCultureFail,
  getCultureSuccess,
  getHistoryData,
  getHistoryDataFail,
  getHistoryDataSuccess,
  getHomeData,
  getHomeDataFail,
  getHomeDataSuccess,
  getJobData,
  getJobDataFail,
  getJobDataSuccess,
  getLeadershipData,
  getLeadershipDataFail,
  getLeadershipDataSuccess,
  getProcessData,
  getProcessDataFail,
  getProcessDataSuccess,
  getProductData,
  getProductDataFail,
  getProductDataSuccess,
  getResponsibilityData,
  getResponsibilityDataFail,
  getResponsibilityDataSuccess,
  getSingleBlogData,
  getSingleBlogDataFail,
  getSingleBlogDataSuccess,
  getSingleJobData,
  getSingleJobDataFail,
  getSingleJobDataSuccess,
  getSingleResponsibilityData,
  getSingleResponsibilityDataFail,
  getSingleResponsibilityDataSuccess,
  getSustainabilityData,
  getSustainabilityDataFail,
  getSustainabilityDataSuccess,
  getVisionariesData,
  getVisionariesFail,
  getVisionariesSuccess,
} from "./homeDataSlice";

function* onGetHomeData() {
  try {
    const url = `/centro-landing-page/get-all-landing-data`;
    const response = yield call(get, url);

    if (response) {
      yield put(getHomeDataSuccess(response?.getData));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get home data failed!";
    yield put(getHomeDataFail({ message }));
  }
}

function* onGetAboutData() {
  try {
    const url = `/centro-landing-page/get-all-about-us-data`;
    const response = yield call(get, url);

    if (response) {
      yield put(getAboutDataSuccess(response?.getData));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get about data failed!";
    yield put(getAboutDataFail({ message }));
  }
}

function* onGetProcessData() {
  try {
    const url = `/centro-landing-page/get-all-process-data`;
    const response = yield call(get, url);

    if (response) {
      yield put(getProcessDataSuccess(response?.getData));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get process data failed!";
    yield put(getProcessDataFail({ message }));
  }
}

function* onGetProductData() {
  try {
    const url = `/centro-landing-page/get-all-product-data`;
    const response = yield call(get, url);

    if (response) {
      yield put(getProductDataSuccess(response?.getData));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get product data failed!";
    yield put(getProductDataFail({ message }));
  }
}

function* onGetSustainabilityData() {
  try {
    const url = `/centro-landing-page/get-all-sustainability-data`;
    const response = yield call(get, url);

    if (response) {
      yield put(getSustainabilityDataSuccess(response?.getData));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get sustainability data failed!";
    yield put(getSustainabilityDataFail({ message }));
  }
}

function* onGetHistoryData() {
  try {
    const url = `/centro-landing-page/get-as-history`;
    const response = yield call(get, url);

    if (response) {
      yield put(getHistoryDataSuccess(response?.getData));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get history data failed!";
    yield put(getHistoryDataFail({ message }));
  }
}

function* onGetLeadershipData() {
  try {
    const url = `/centro-landing-page/get-as-leadership`;
    const response = yield call(get, url);

    if (response) {
      yield put(getLeadershipDataSuccess(response?.getData));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get leadership data failed!";
    yield put(getLeadershipDataFail({ message }));
  }
}

function* onGetAsEmployeeData() {
  try {
    const url = `/centro-landing-page/get-as-employee`;
    const response = yield call(get, url);

    if (response) {
      yield put(getAsEmployeeDataSuccess(response?.getData));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get as employee data failed!";
    yield put(getAsEmployeeDataFail({ message }));
  }
}

function* onGetAsPartnerData() {
  try {
    const url = `/centro-landing-page/get-as-partner`;
    const response = yield call(get, url);

    if (response) {
      yield put(getAsPartnerDataSuccess(response?.getData));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get as partner data failed!";
    yield put(getAsPartnerDataFail({ message }));
  }
}

function* onGetVisionariesData() {
  try {
    const url = `/centro-landing-page/get-visonary`;
    const response = yield call(get, url);

    if (response) {
      yield put(getVisionariesSuccess(response?.getData));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get visionaries failed!";
    yield put(getVisionariesFail({ message }));
  }
}

function* onGetBlogData() {
  try {
    const url = `/centro-landing-page/get-all-blog-data`;
    const response = yield call(get, url);

    if (response) {
      yield put(getBlogDataSuccess(response?.getData));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get blog data failed!";
    yield put(getBlogDataFail({ message }));
  }
}

function* onGetSingleBlogData({ payload: { id } }) {
  try {
    const url = `/centro-landing-page/get-single-blog/${id}`;
    const response = yield call(get, url);

    if (response) {
      yield put(getSingleBlogDataSuccess(response?.getData));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get blog data failed!";
    yield put(getSingleBlogDataFail({ message }));
  }
}

function* onGetJobData({ payload: { skip = 0, limit = 3 } }) {
  try {
    const url = `/centro-landing-page/get-job-post?skip=${skip}&limit=${limit}&user=true`;
    const response = yield call(get, url);

    if (response) {
      yield put(getJobDataSuccess(response?.getData));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get jobs failed!";
    yield put(getJobDataFail({ message }));
  }
}

function* onGetSingleJobData({ payload: { id } }) {
  try {
    const url = `/centro-landing-page/get-single-job-post/${id}`;
    const response = yield call(get, url);

    if (response) {
      yield put(getSingleJobDataSuccess(response?.getData));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get job failed!";
    yield put(getSingleJobDataFail({ message }));
  }
}

// CV Upload
function* onApplyExistingJob({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle },
  },
}) {
  try {
    const url = `/centro-landing-page/drop-cv`;
    const response = yield call(post, url, data);

    if (response) {
      toaster("success", "CV drop successfully.");
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message === "Already exist"
        ? "You already applied for this position."
        : error?.response?.data?.message || `CV drop failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onApplyOpenCv({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle },
  },
}) {
  try {
    const url = `/centro-landing-page/drop-open-cv`;
    const response = yield call(post, url, data);

    if (response) {
      toaster("success", "CV drop successfully.");
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message === "Already exist"
        ? "You already applied for this position."
        : error?.response?.data?.message || `CV drop failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetCultureData() {
  try {
    const url = `/centro-landing-page/get-culture-data`;
    const response = yield call(get, url);

    if (response) {
      yield put(getCultureSuccess(response?.getData));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Partner failed!";
    yield put(getCultureFail({ message }));
  }
}

function* onGetResponsibilityData() {
  try {
    const url = `/centro-landing-page/get-social-data`;
    const response = yield call(get, url);

    if (response) {
      yield put(getResponsibilityDataSuccess(response?.getData));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get responsibility data failed!";
    yield put(getResponsibilityDataFail({ message }));
  }
}

function* onGetSingleResponsibilityData({ payload: { id } }) {
  try {
    const url = `/centro-landing-page/get-single-social-data/${id}`;
    const response = yield call(get, url);

    if (response) {
      yield put(getSingleResponsibilityDataSuccess(response?.getData));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get responsibility data failed!";
    yield put(getSingleResponsibilityDataFail({ message }));
  }
}

export function* homeSaga() {
  yield takeEvery(getHomeData.type, onGetHomeData);
  yield takeEvery(getAboutData.type, onGetAboutData);
  yield takeEvery(getProcessData.type, onGetProcessData);
  yield takeEvery(getProductData.type, onGetProductData);
  yield takeEvery(getSustainabilityData.type, onGetSustainabilityData);
  yield takeEvery(getHistoryData.type, onGetHistoryData);
  yield takeEvery(getLeadershipData.type, onGetLeadershipData);
  yield takeEvery(getAsEmployeeData.type, onGetAsEmployeeData);
  yield takeEvery(getAsPartnerData.type, onGetAsPartnerData);
  yield takeEvery(getVisionariesData.type, onGetVisionariesData);
  yield takeEvery(getBlogData.type, onGetBlogData);
  yield takeEvery(getSingleBlogData.type, onGetSingleBlogData);
  yield takeEvery(getJobData.type, onGetJobData);
  yield takeEvery(getSingleJobData.type, onGetSingleJobData);
  yield takeEvery(applyExistingJob.type, onApplyExistingJob);
  yield takeEvery(applyOpenCv.type, onApplyOpenCv);
  yield takeEvery(getCultureData.type, onGetCultureData);
  yield takeEvery(getResponsibilityData.type, onGetResponsibilityData);
  yield takeEvery(
    getSingleResponsibilityData.type,
    onGetSingleResponsibilityData
  );
}
