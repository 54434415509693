import React from "react";
import Slider from "react-slick";
import { IMAGE_URL } from "../../helpers/api_helper";

export default function MultiImage({
  images = [],
  xl = images?.length || 5,
  lg = 3,
  md = 2,
  sm = 2,
}) {
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: xl,
    slidesToScroll: 2,
    easing: "linear",
    autoplay: false,
    pauseOnHover: false,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: lg,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: md,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: sm,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings}>
        {images?.map((el, i) => (
          <div
            key={i}
            style={{
              outline: "none",
            }}
            className="hover-image"
          >
            <img src={IMAGE_URL + el} alt="" className="mb-0 px-1 w-100" />
          </div>
        ))}
      </Slider>
    </div>
  );
}
